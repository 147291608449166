import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const FourZeroFour: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  const translations = {
    en: {
      oops: 'Oops!',
      page_not_found: 'Page not found',
      page_does_not_exists: 'The page you’re looking for doesn\'t exist.',
      go_back: 'Go Back to Shop Page',
    },
    zh: {
      oops: '哎呀！',
      page_not_found: '找不到网页',
      page_does_not_exists: '您要查找的页面不存在。',
      go_back: '返回商店页面',
    }
  }
  return (
    <Layout page="404-page" locale={language}>
      <SEO title="404 Not Found" />
      <div className="">
        <div className="px-40 py-20 rounded-md">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-blue-600 text-xl7">404</h1>

            <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
              <span className="text-red-500">{translations[language].oops}</span> {translations[language].page_not_found}
            </h6>

            <p className="mb-8 text-center text-gray-500 md:text-lg">
              {translations[language].page_does_not_exists}
            </p>

            <a href="#" className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100">
              {translations[language].go_back}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FourZeroFour;
